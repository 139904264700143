/* global breadcrumbs_block_container, s */
import { isEmpty, isNull, last } from 'lodash';
import {
  addClassName,
  getAllBySelector,
  getElementByID,
  getFirstBySelector,
  hasClassName,
  px,
  removeClassName,
  setStyle,
  readDatalayer
} from './utils';
import { add_quantity_controls } from '../products/quantity_controls';
import { watchPriceBreaks } from '../prices/watchPriceBreaks';
import listsLoader from '../listsLoader';
import { setupPlpImageCarousels } from '../carousels/PlpImageCarousel';
import { insertMerchRows } from '../merch/insertMerchRows';
import { ProductCarousel } from '../carousels/ProductCarousel';
import { SaveCartReminder } from '../addToCart/SaveCartReminder';
import { addNotificationEvent } from '../notification/stock';
/********************************
 * helpers
 *******************************/

const updateMastHeadTracks = masthead => () => {
  if (Math.random() < 0.01) {
    s.linkTrackVars = 'eVar71,prop71';
    s.eVar71 = s.prop71 = hasClassName(masthead, 'sticky') ? 'sticky' : 'full';
    s.tl(true, 'o', 'masthead');
  }
};

const checkSticky = (
  scrollingMastheadInitial,
  scrollingMasthead,
  stickyStart,
  masthead,
  MainMenuContainer,
  mOffset
) => {
  if (pageYOffset > scrollingMastheadInitial + mOffset) {
    addClassName(scrollingMasthead, 'sticky');
  } else {
    removeClassName(scrollingMasthead, 'sticky');
  }
  if (pageYOffset > stickyStart) {
    addClassName(masthead, 'sticky');
    addClassName(MainMenuContainer, 'sticky');
  } else {
    removeClassName(masthead, 'sticky');
    removeClassName(MainMenuContainer, 'sticky');
  }
};

const handleScroll =
  (
    doSticky,
    scrollingMastheadInitial,
    scrollingMasthead,
    stickyStart,
    masthead,
    MainMenuContainer,
    mOffset
  ) =>
    () => {
      if (doSticky) {
        checkSticky(
          scrollingMastheadInitial,
          scrollingMasthead,
          stickyStart,
          masthead,
          MainMenuContainer,
          mOffset
        );
      }
      const backTo = getElementByID('btto');
      if (pageYOffset > 500) {
        addClassName(backTo, 'display-back-to-top');
      } else {
        removeClassName(backTo, 'display-back-to-top');
      }
    };

/*Browser detection*/
const getBrowser = () => {
  const ua = navigator.userAgent;
  let tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];

  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return 'IE ' + (tem[1] || '');
  }

  if (M[1] === 'Chrome') {
    tem = ua.match(/\bOPR\/(\d+)/);
    if (tem != null) {
      return 'Opera ' + tem[1];
    }
  }

  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  tem = ua.match(/version\/(\d+)/i);
  if (tem && tem[1]) {
    M.splice(1, 1, tem[1]);
  }
  return M[0];
};

/*Back To Top and sticky masthead*/
const setupSticky = () => {
  let masthead;
  let mOffset;
  let MainMenuContainer;
  let scrollingMasthead;
  let scrollingMastheadInitial;
  let stickyStart;
  const doSticky = getElementByID('search_input');

  if (typeof pageYOffset === 'undefined') {
    return;
  }

  if (doSticky) {
    const stickyHeight = 60;
    masthead = getFirstBySelector('.masthead-wrapper');
    MainMenuContainer = getFirstBySelector(
      '.container.main-menu-container-wrapper'
    );
    scrollingMasthead = getFirstBySelector('.scrolling-masthead');
    mOffset = getFirstBySelector('.masthead-wrapper').offsetTop;
    scrollingMastheadInitial = scrollingMasthead.offsetTop;
    stickyStart = mOffset + 109 - stickyHeight;
    checkSticky(
      scrollingMastheadInitial,
      scrollingMasthead,
      stickyStart,
      masthead,
      MainMenuContainer,
      mOffset
    );
    masthead.addEventListener('click', updateMastHeadTracks(masthead));
  }
  window.addEventListener(
    'scroll',
    handleScroll(
      doSticky,
      scrollingMastheadInitial,
      scrollingMasthead,
      stickyStart,
      masthead,
      MainMenuContainer,
      mOffset
    )
  );
};
/*Breadcrumb setting for Mobile devices*/
const breadcrumbSetup = () => {
  let entireWidth,
    breadcrumbItemList,
    lastThree,
    currentOne,
    remWidth,
    remWidthEx,
    textWidth;

  entireWidth = getElementByID('breadcrumbs_block_container').offsetWidth;
  breadcrumbItemList = getAllBySelector('.breadcrumbItemList');
  lastThree = breadcrumbItemList.length - 3;
  currentOne = last(breadcrumbItemList).offsetWidth;
  remWidth = (entireWidth - currentOne) * 0.5;
  remWidthEx = px((entireWidth - currentOne) * 0.5 - 16);

  breadcrumbItemList.forEach((item, i) => {
    textWidth = item.offsetWidth;
    if (i == lastThree) {
      item.down('span.separator').style.display = 'none';
      textWidth -= 16;
    }
    addClassName(item, 'breadcrumb_cls');
    if (textWidth > remWidth) {
      setStyle(item, { width: remWidthEx });
      item.innerHTML += '<span class="info">...</span>';
    }
  });

  breadcrumbItemList.slice(-3).forEach((item, index) => {
    if (index === 0) {
      item.down('span.separator').style.display = 'none';
      textWidth -= 16;
    }
    addClassName(item, 'breadcrumb_cls');
    if (textWidth > remWidth) {
      setStyle(item, { width: remWidthEx });
      item.innerHTML += '<span class="info">...</span>';
    }
  });

  addClassName(last(breadcrumbItemList), 'breadcrumb_cls');
};

const setupBrowser = () => {
  /*Mobile OS Detection*/
  if (navigator.userAgent.match(/Android/i)) {
    addClassName(document.body, 'android');
  }
  if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
    addClassName(document.body, 'ios');
  }
  const browser = getBrowser().toLowerCase();
  addClassName(getElementByID('body'), browser);
  addClassName(getElementByID('masthead_mobile'), browser);
  if (document.all && !window.atob) {
    addClassName(getElementByID('body'), 'internet_exp9lt');
  }
};
/********************************
 * helpers inspired by prototype.js
 *******************************/
const onPageLoad = () => {
  if (screen.width < 769) {
    addClassName(document.body, 'mobile_ver');
  }
  if (
    screen.width < 480 &&
    typeof breadcrumbs_block_container !== 'undefined'
  ) {
    breadcrumbSetup();
  }
  setupSticky();
  setupBrowser();
  /*Special offer*/
  if (!isEmpty(getAllBySelector('.specialoffer_wrap'))) {
    const specPrice = getAllBySelector('.price');
    specPrice.forEach(price => {
      addClassName(price, 'spec_disc_offer');
    });
  }
};

/* dom_has_loaded start */
export const dom_has_loaded = () => {
  /* add Class for product detail page*/
  const body = document.getElementById('body');
  const mobile_search = document.getElementById('mobile_search');
  if (/$\/main-catalogue-productdetail\//.test(window.location.href)) {
    addClassName(body, 'main_catalogue_productdetail');
  }
  if (/$\/main-catalogue-browse\//.test(window.location.href)) {
    addClassName(body, 'main_catalogue_browse');
  }
  if (/$\/main-my-login\//.test(window.location.href)) {
    addClassName(mobile_search, 'hide_search_icon');
  }
  if (/$\/main-my-trouble\//.test(window.location.href)) {
    addClassName(mobile_search, 'hide_search_icon');
  }
  /*Pagination fixes*/
  const mobile_sel = document.querySelectorAll('.mobile_sel');
  if (mobile_sel.length != 0) {
    const page_sel = document.querySelectorAll('.mobile_sel');
    for (let i = 0; i < page_sel.length; i++) {
      if (typeof page_sel[i].previousSibling != "undefined") {
        addClassName(page_sel[i].previousSibling, 'selelet_page');
      }
    }
  }

  if (screen.width < 769) {
    /*Brightcove Video width fixes*/
    const screen_actual_width = screen.width - 5;
    const BrightcoveExperience = document.querySelectorAll('.BrightcoveExperience');
    if (BrightcoveExperience.length != 0) {
      const Brightcove_height = screen_actual_width * 0.6;
      for (let i = 0; i < BrightcoveExperience.length; i++) {
        BrightcoveExperience[i].style.width = screen_actual_width + "px";
        BrightcoveExperience[i].style.height = Brightcove_height + "px";
      }
    }
  }

  /* Restyle large quantity fields */
  add_quantity_controls();

  // watch for price break changes
  watchPriceBreaks();

  // ajax add to list
  if (readDatalayer(['global', 'streams', 'control_all'])) { /* was 'list' */
    window.listLoader = new listsLoader();
  }

  // if on PLP
  setupPlpImageCarousels();
  // if on PLP, maybe insert inline merch rows
  insertMerchRows();
  //  carouselise slidables
  document.querySelectorAll('.slidable').forEach(function (elem) {
    elem.productCarousel = elem.productCarousel || new ProductCarousel(elem); // eslint-disable-line no-use-before-define
  });
  // add elipsis to long card titles
  document.querySelectorAll('.griditem_title, .itemcard__description a').forEach(function (elem) {
    if (elem.textContent && elem.textContent.length > 10) {
      /* Don't do this if no text content is loaded, or if it's very short */
      window.Ellipsity.ellipsify(elem);
    }
  });
  // cart reminder
  window.cartReminder = new SaveCartReminder(600000);
  // stock-notification checkbox on similar-products page
  if (/\/main-catalogue-(similar|productdetail)\//.test(window.location.href)) {
    addNotificationEvent();
  }
}
/* dom_has_loaded end */

export default onPageLoad;
